<template>
  <section>
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <section>
      <h2 class="titulo-links-escolha">Professor(a): {{ nomeProfessor }}</h2>

      <div class="pm-card">
        <pm-DataTable
          :value="professorInfo"
          dataKey="id"
          :rowHover="true"
          filterDisplay="menu"
          v-model:filters="filters"
          responsiveLayout="scroll"
        >
          <pm-Column
            field="nome"
            sortable
            header="NOME DA TURMA"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <div>
                <b>{{ data.turma[0].nome }}</b
                ><br />
                <b>{{ data.turma[0].escola }}</b
                ><br />
                <b>{{ data.turma[0].segmento }}</b
                ><br />
                <b>{{ data.turma[0].serie }}</b
                ><br />
                <b>{{ data.turma[0].turno }}</b
                ><br />
                <b>{{ data.turma[0].ano }}</b
                ><br />
              </div>
            </template>
          </pm-Column>
          <pm-Column
            field="nome"
            sortable
            header="NOME DA DISCIPLINA"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              {{ data.disciplina }}
            </template>
          </pm-Column>
        </pm-DataTable>
      </div>

      <!--<div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="th_prf">Turma</th>
                    <th class="th_prf">Nome</th>
                    <th class="th_prf">Disciplina</th>
                  </tr>
                </thead>
                <tbody class="tbody_prf">
                  <tr v-for="n in professorInfo" :key="n">
                    <td style="text-align: center;">
                      <b>{{n.turma[0].escola}}</b><br>
                      <b>{{n.turma[0].segmento}}</b><br>
                      <b>{{n.turma[0].serie}}</b><br>
                      <b>{{n.turma[0].turno}}</b><br>
                      <b>{{n.turma[0].ano}}</b><br>
                    </td>
                    <td ><b>{{n.turma[0].nome}}</b></td>
                    <td ><b>{{n.disciplina}}</b></td>
                  </tr>
                </tbody>
              </table>
           </div> -->
    </section>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Turma } from "@/class/turma.js";
import { Calendario } from "@/class/calendario.js";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { Servidores } from "@/class/servidores.js";

export default defineComponent({
  props: {
    id: {},
    pg: {},
  },
  data() {
    const columns = [
      { key: "turma", label: "Turma", sortable: true },
      { key: "turno", label: "Turno", sortable: true },
      { key: "disciplinas", label: "Disciplinas" },
    ];
    return {
      home: {
        icon: "pi pi-home",
        to: "/ambiente-coordenacao/coordenacao-escolas",
      },
      items_bread: [{ label: "Professor" }],
      nomeProfessor: "",
      professorInfo: [],
      ano: null,
      columns,
      perPage: 5,
      currentPage: 1,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.professorInfo.length / this.perPage)
        : this.professorInfo.length;
    },
  },
  methods: {
    async buscarProfessor() {
      const data = await Servidores.obtemUm(this.id);
      this.nomeProfessor = data.data[0].nome;
    },
    async buscarCalendarioAtual() {
      const data = await Calendario.calendarioAtual();
      this.ano = data.data.ano;
    },
    async listarTudo() {
      const payload = {
        ano: this.ano,
        professor: this.id,
      };

      try {
        const data = await Turma.obterProfessorTurmasAno(payload);
        this.professorInfo = data.data;
      } catch (error) {
        if (error.response.status === 500) {
          this.$vaToast.init({
            message: "Ocorreu um problema!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 4500,
            fullWidth: false,
            color: "danger",
          });
        }
      }
    },
  },
  async beforeMount() {
    if (this.id == null) {
      this.$router.push({ name: "coordenacao-professores" });
    } else {
      await this.buscarCalendarioAtual();
      this.buscarProfessor();
      this.listarTudo();
    }
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
.th_prf {
  background-color: #0067a7 !important;
  color: #fff;
  text-align: left;
  padding: 15px !important;
  padding-left: 25px !important;
  border: 1px solid;
}
.tbody_prf {
  border-top: 0px solid currentColor !important;
}
</style>
